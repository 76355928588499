import Box from '@mui/material/Box';
import Image from 'next/image';
import { motion } from 'framer-motion';
import { HeroBackgroundImageProps } from '@organisms/HeroParagraph/HeroBackgroundImage/HeroBackgroundImage.props';
import { forwardRef } from 'react';

const HeroBackgroundImage = forwardRef<HTMLDivElement, HeroBackgroundImageProps>(function HeroBackgroundImage(
  { imageUrl },
  ref
) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      ref={ref}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
    >
      <Box
        sx={{
          position: 'absolute',
          borderRadius: 6,
          overflow: 'hidden',
          width: { xs: 'calc(100% + 3rem)', lg: 'calc(100% - 1rem)' },
          left: { xs: -22, lg: 0 },
          top: 0,
          height: 620,
        }}
      >
        <Image
          priority
          src={imageUrl}
          alt=""
          fill
          style={{ objectFit: 'cover', objectPosition: 'center' }}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 70vw, 50vw"
        />
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0.08%, rgba(0, 0, 0, 0) 56.78%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
          }}
        />
      </Box>
    </motion.div>
  );
});

export default HeroBackgroundImage;
